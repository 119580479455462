import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "preview"]

  connect () {
    this.change()
  }

  change (event, color = "#000000") {
    this.previewTarget.style.backgroundColor = !!this.inputTarget.value ? this.inputTarget.value : color
  }
}