import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["errorMessage"]

  connect () {
    // 10MB in bytes -> 10 * 1024 * 1024
    this.maxFileSize = 10485760 
  }

  checkFileSize(event) {
    let exceedsLimit = event.currentTarget.files[0].size > this.maxFileSize
    
    this.errorMessageTarget.classList.toggle("hidden", !exceedsLimit)
    if (exceedsLimit) event.currentTarget.form.reset()
  }
}
