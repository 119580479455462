import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
    static targets = ["input", "content", "placeholder"]
    static debounces = ["changed"]
    static values = { debounceDuration: { type: Number, default: 400 } }

    connect() {
        const duration = this.debounceDurationValue
        useDebounce(this, { wait: duration })
    }

    changed(event) {
        const trimmedContent = event.target.innerText.trim()
        this.inputTarget.value = trimmedContent
        this.inputTarget.form?.requestSubmit()
    }

    trimContent() {
        this.contentTarget.innerText = this.contentTarget.innerText.trim()
    }

    handlePlaceholderFocus() {
        this.contentTarget.focus()
    }

    togglePlaceholder() {
        const trimmedContent = this.contentTarget.innerText.trim() 
        this.placeholderTarget.classList.toggle("hidden", !!trimmedContent)
    }
}
