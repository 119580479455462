import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scroll (event) {
    event.preventDefault()

    document.querySelector(this.element.getAttribute("href")).scrollIntoView({
      behavior: "smooth"
    })
  }
}
