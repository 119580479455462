import Sortable from "sortablejs"
import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

export default class extends Controller {
  static targets = ["item", "sortableList", "listDropzone"]

  connect() {
    this.initializeDraggable()
    document.addEventListener("turbo:load", this.initializeDraggable)
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.initializeDraggable)
  }

  initializeDraggable = () => {
    if (!this.hasSortableListTarget) return
    this.currentlyDraggedItem = null

    this.sortableList = Sortable.create(this.sortableListTarget, {
      group: "list_tasks",
      handle: ".draggable--handle",
      animation: 150,
      swapThreshold: 0.5,
      onEnd: async event => {
        if (event.newIndex === event.oldIndex) return
        await this.updateTask({ position: event.newIndex })
      }
    })
  }

  // Handlers
  dragstart(event) {
    this.currentlyDraggedItem = event.currentTarget
  }

  dragover(event) {
    event.preventDefault()
    this.toggleListDropzoneClass(event, true)
  }

  dragleave(event) {
    this.toggleListDropzoneClass(event, false)
  }

  async drop(event) {
    event.preventDefault()
    if (!this.listDropzoneTargets.includes(event.currentTarget)) return

    this.toggleListDropzoneClass(event, false)
    await this.updateTask({ list_id: event.currentTarget.dataset.listId })
  }

  // Helpers & Decorators
  isCurrentList(event) {
    return this.currentlyDraggedItem.dataset.listId === event.currentTarget.dataset.listId
  }

  toggleListDropzoneClass(event, enabled) {
    const element = this.listDropzoneTargets.find(target => target.contains(event.target))
    if (!element || this.isCurrentList(event)) return

    element.classList.toggle("border-emerald-500", enabled)
  }

  // API
  async updateTask(payload) {
    if (!this.currentlyDraggedItem) return

    const taskId = this.currentlyDraggedItem.dataset.taskId
    const currentListId = this.currentlyDraggedItem.dataset.listId

    if (payload?.list_id === currentListId) return

    try {
      const response = await put(`/tasks/${taskId}`, {
        responseKind: "json",
        body: JSON.stringify(payload)
      })

      if (response.ok) {
        if (!!payload.list_id && !!this.currentlyDraggedItem.parentNode) {
          this.currentlyDraggedItem.parentNode.removeChild(this.currentlyDraggedItem)
        }
      } else {
        console.error("Unfortunately, something went wrong...")
      }
    } catch (error) {
      console.error("Woah, something went wrong...", error)
      this.currentlyDraggedItem = null
    }
  }
}
