import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "monthlyPrice", "yearlyPrice"]
  static values = {
    period: String
  }

  connect() {
    this.monthly = this.periodValue === "monthly"
  }

  toggle(event) {
    this.monthly = !this.monthly
    event.target.classList.toggle("bg-blue-500", this.monthly)
    event.target.classList.toggle("bg-gray-200", !this.monthly)
    event.target.children[0].classList.toggle("translate-x-5", this.monthly)
    event.target.children[0].classList.toggle("translate-x-0", !this.monthly)
    this.monthlyPriceTarget.classList.toggle("hidden", this.monthly)
    this.yearlyPriceTarget.classList.toggle("hidden", !this.monthly)
  }
}
