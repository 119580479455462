import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    duration: Number
  }

  connect () {
    const that = this
    
    let timer = this.durationValue - 1
    let minutes, seconds

    const interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      minutes = minutes < 10 ? "0" + minutes : minutes
      seconds = seconds < 10 ? "0" + seconds : seconds

      that.element.textContent = minutes + ":" + seconds
      if (--timer < 0) clearInterval(interval)
    }, 1000)
  }
}
