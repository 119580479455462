
import * as Turbo from '@hotwired/turbo'
Turbo.start()

// Importing Stimulus and controllers
import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
application.debug = process.env.NODE_ENV === "development"
application.warnings = true
window.Stimulus = application


const controllers = import.meta.glob('~/controllers/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

import Timeago from 'stimulus-timeago'
application.register('timeago', Timeago)

import PasswordVisibilityController from 'stimulus-password-visibility'
application.register('password-visibility', PasswordVisibilityController)


// for (let path in controllers) {
//   let module = controllers[path];
//   let name = path.match(/\.\/(.+)_controller\.js$/)[1].replaceAll("/", "--");
//   console.log(name)
//   application.register(name, module.default);
// }




// // Import all channels.
// const channels = import.meta.globEager('../**/*_channel.js')

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//

// Example: Import a stylesheet in app/frontend/index.css
// import "./application.css"

// Setting the timezone before stimulus is loaded
setTimezoneCookie("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone)
function setTimezoneCookie (name, value) {
  const expires = new Date()
  expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value};expires=${expires.toUTCString()}`
}