import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menuIcon", "closeIcon"]

  toggle() { 
    document.querySelector("#workspace-sidebar-wrapper").classList.toggle("hidden")
    this.menuIconTarget.classList.toggle("hidden")
    this.closeIconTarget.classList.toggle("hidden")
  }
}
