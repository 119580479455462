import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

export default class extends Controller {
  static targets = ["editable", "editButton", "saveButton"]
  static values = {
    url: String,
    attribute: String
  }

  connect () {
    this.editing = false
    console.log(this.urlValue)
  }

  toggleEditable () {
    this.editing = !this.editing

    if (this.editing) {
      this.editableTarget.setAttribute("contenteditable", "")
      this.editableTarget.focus()
    } else {
      this.editableTarget.removeAttribute("contenteditable")
    }

    this.editButtonTarget.classList.toggle("hidden")
    this.saveButtonTarget.classList.toggle("hidden")
  }

  async save () {
    const url = this.urlValue
    let payload = {}
    payload[this.attributeValue] = this.editableTarget.innerText

    const response = await put(url, {
      responseKind: "application/json",
      body: JSON.stringify(payload)
    })

    if (response.ok) {
      this.toggleEditable()
    }
  }
}
