import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["billingAddressFields", "physicalAddressFields", "checkbox"]

  connect () {
    this.toggle()
  }
  
  toggle () {
    this.physicalAddressFieldsTarget.classList.toggle("hidden", this.checkboxTarget.checked)
    this.billingAddressFieldsTarget.classList.toggle("col-span-1", !this.checkboxTarget.checked)
    this.billingAddressFieldsTarget.classList.toggle("col-span-full", this.checkboxTarget.checked)
  }
}
