import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["buttonText", "recordIcon", "stopIcon", "ping", "visualizer"]
  static values = { endpointUrl: String, durationLimit: Number }

  connect () {
    this.setMimeType()
    this.defaultButtonText = "Press to record a new task"
    // this.defaultButtonText = this.buttonTextTarget.innerText // Commenting out due to Safari ?
    this.recording = false
    this.chunks = []
  }

  async record (options = {}) {
    this.recording ? this.handleRecordingStop() : this.handleRecordingStart()
    this.recording = !this.recording

    this.recordIconTarget.classList.toggle("hidden")
    this.stopIconTarget.classList.toggle("hidden")
    this.pingTarget.classList.toggle("hidden")
    this.buttonTextTarget.innerHTML = this.recording ? "-:-- | Stop recording" : this.defaultButtonText
  }

  async handleRecordingStart () {
    await this.getUserMedia()
    this.audioRecorder.start()
    this.setCountdown()
  }

  async handleRecordingStop () {
    clearInterval(this.countdown)
    this.audioRecorder.stop()
    this.chunks = []
    this.audioRecorder.mediaRecorder = null
    this.streamBeingCaptured = null
    this.stream.getTracks().forEach(track => track.stop())
  }

  async getUserMedia () {
    const that = this
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      that.stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      })
      that.audioRecorder = new MediaRecorder(that.stream, { mimeType: that.mimeType })
    } else {
      console.error("Recording not supported on this device")
    }

    this.audioRecorder.ondataavailable = function (event) {
      that.chunks.push(event.data)
    }

    this.audioRecorder.onstop = async function (event) {
      const blob = new Blob(that.chunks, { type: that.mimeType })
      await that.persistAudio(blob)
    }
  }

  async persistAudio (blob) {
    const extention = this.extention
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0))
    const filename = `orato-${timestamp}-recording.${extention}`
    const data = new File([blob], filename)
    const formData = new FormData()
    formData.append("audio_blob", data)
    await post(this.endpointUrlValue, { body: formData, responseKind: "turbo-stream" })
  }

  setCountdown () {
    const that = this
    let timer = this.durationLimitValue / 1000
    let minutes, seconds

    this.countdown = setInterval(function () {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      minutes = minutes < 10 ? "0" + minutes : minutes
      seconds = seconds < 10 ? "0" + seconds : seconds

      that.buttonTextTarget.innerHTML = `${minutes}:${seconds} | Stop recording`
      if (--timer < 0) that.record()
    }, 1000)
  }

  setMimeType () {
    if (MediaRecorder.isTypeSupported("audio/webm")) {
      this.mimeType = "audio/webm"
      this.extention = "webm"
    }
    if (MediaRecorder.isTypeSupported("audio/mp4")) {
      this.mimeType = "audio/mp4"
      this.extention = "mp4"
    }
  }
}

// Audio context visualization
// Leaving this here for now if decided to make it more visual
// connect() {
//   this.visualizerCanvas = this.visualizerTarget.getContext("2d")
//   this.visualizerCanvas.imageSmoothingEnabled = false
// }
// visualize (stream) {
//   const that = this
//   if (!this.audioContext) this.audioContext = new AudioContext()

//   const source = this.audioContext.createMediaStreamSource(stream)

//   const analyser = this.audioContext.createAnalyser()
//   analyser.fftSize = 2048
//   source.connect(analyser)

//   const bufferLength = analyser.frequencyBinCount
//   const dataArray = new Uint8Array(bufferLength)

//   draw()

//   function draw () {
//     const WIDTH = that.visualizerTarget.width
//     const HEIGHT = that.visualizerTarget.height

//     requestAnimationFrame(draw)

//     analyser.getByteTimeDomainData(dataArray)

//     that.visualizerCanvas.fillStyle = "rgb(255, 255, 255)"
//     that.visualizerCanvas.fillRect(0, 0, WIDTH, HEIGHT)

//     // const barWidth = (WIDTH / bufferLength) * 2.5 - 1
//     const barWidth = 2
//     let barHeight
//     let x = 0

//     for (let i = 0; i < bufferLength; i++) {
//       barHeight = dataArray[i]

//       that.visualizerCanvas.fillStyle = "rgb(0, 0, 0)"
//       that.visualizerCanvas.fillRect(x, (HEIGHT - barHeight/2)/2, barWidth, barHeight / 4)

//       x += barWidth + 2
//     }

//     // that.visualizerCanvas.fillStyle = "#ffffff" // 'rgb(200, 200, 200)';
//     // that.visualizerCanvas.fillRect(0, 0, WIDTH, HEIGHT)

//     // that.visualizerCanvas.lineWidth = 4
//     // that.visualizerCanvas.strokeStyle = "#000000" //'rgb(0, 0, 0)';

//     // that.visualizerCanvas.beginPath()

//     // let sliceWidth = (WIDTH * 1.0) / bufferLength
//     // let x = 0

//     // for (let i = 0; i < bufferLength; i++) {
//     //   let v = dataArray[i] / 128.0
//     //   let y = (v * HEIGHT) / 2

//     //   i === 0 ? that.visualizerCanvas.moveTo(x, y) : that.visualizerCanvas.lineTo(x, y)
//     //   x += sliceWidth
//     // }

//     // that.visualizerCanvas.lineTo(that.visualizerTarget.width, that.visualizerTarget.height / 2)
//     // that.visualizerCanvas.stroke()
//   }
// }
