import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["modal"]
  static values = {
    callback: String,
    redirectPath: String
  }

  keyboardClear (event) {
    if (event.code === "Escape") this.clear()
  }

  backgroundClear (event) {
    if (!this.modalTarget.contains(event.target)) this.clear()
  }

  submitEnd (event) {
    // if (event.detail.success) {
    // }
    this.clear()
  }

  clear () {
    this.element.remove()
    if(this.hasRedirectPathValue) Turbo.visit(this.redirectPathValue)
  }
}
