import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["revealable", "openButton", "closeButton"]

  toggle() {
    this.revealableTarget.classList.toggle("hidden")
    this.openButtonTarget.classList.toggle("hidden")
    this.closeButtonTarget.classList.toggle("hidden")
  }
}
