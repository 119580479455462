import { Controller } from "@hotwired/stimulus"
import WaveSurfer from "wavesurfer.js"

export default class extends Controller {
  static targets = ["wave", "playIcon", "pauseIcon", "playbackRate"]
  static values = {
    src: String
  }

  connect () {
    const waveElement = this.hasWaveTarget ? this.waveTarget : this.element

    this.playbackRates = [1, 1.25, 1.5, 0.75]
    this.playbackRateIndex = 0

    this.player = WaveSurfer.create({
      container: waveElement,
      waveColor: "#737986",
      progressColor: "#000000",
      barMinHeight: 2,
      closeAudioContext: true,
      barWidth: 3,
      barRadius: 2,
      cursorWidth: 0,
      height: 48,
      barGap: 2,
    })

    this.player.load(this.srcValue)

    this.player.on("finish", () => {
      this.playIconTarget.classList.toggle("hidden", false)
      this.pauseIconTarget.classList.toggle("hidden", true)
    })
  }

  toggle () {
    this.player.playPause()
    this.playIconTarget.classList.toggle("hidden")
    this.pauseIconTarget.classList.toggle("hidden")
  }

  togglePlaybackRate() {
    this.playbackRateIndex = (this.playbackRateIndex + 1) % this.playbackRates.length
    let playbackRate = this.playbackRates[this.playbackRateIndex]
    this.player.setPlaybackRate(playbackRate)
    this.playbackRateTarget.innerHTML = playbackRate + "x"
  }

  skip(event) {
    console.log(event.params)
    this.player.skip(event.params.offset)
  }

  disconnect() {
    this.player.destroy()
  }
}
